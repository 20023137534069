
export function queryEncode(object: { [k: string]: string | number | boolean | undefined | null; }): string {
    var query = new URLSearchParams(Object.fromEntries(
        Object.entries(object)
            .filter(([k, v]) => v !== undefined && v !== null)
            .map(([k, v]) => [k, v + ""])
    )).toString();
    return query.length ? "?" + query : "";
}

export function queryDecode(query: string) {
    if (query.includes("?")) query = query.split("?")[1];
    return Object.fromEntries([...new URLSearchParams(query).entries()]);
}
export function applyURLParams(url: string, params: Record<string, string | number | undefined>) {
    params = { 
        ...(url.includes("?") ? queryDecode(url) : {}), 
        ...params
    };
    return url.split("?")[0] + queryEncode(params);
}
